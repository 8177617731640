@import "variables";
$duration: .4s;

body{
  background-image: url('../img/unsplash2.jpg') !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.glass{
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.2);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.login-container{
  position: absolute;
  left: 50%;
  top: max(50%, 350px);
  transform: translate(-50%, -50%);
}

.login-logo {
  width: 98%;
  height: 62px;
  position: fixed;
  left: 1%;
  top: 55px;
  background: url("../img/ivideo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}