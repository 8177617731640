body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("unsplash2.c5a83ca8.jpg") !important;
}

.glass {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-color: #0003;
  position: absolute;
  inset: 0;
}

.login-container {
  position: absolute;
  top: max(50%, 350px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-logo {
  width: 98%;
  height: 62px;
  background: url("ivideo.b476f456.svg") center / contain no-repeat;
  position: fixed;
  top: 55px;
  left: 1%;
}

/*# sourceMappingURL=login.css.map */
